























import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StyleSubjectBackground from '@/components/atoms/StyleSubjectBackground.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

import DrillWebApi from '@/mixins/drills/WebApi'

export type Unit = {
  curriculumSUnitId: number
  name: string
  hasMovie: boolean // 動画視聴可否 API結果を元にする
}

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class AssistMaterialUnitInformationRemoveBackground extends Mixins(
  StyleSubjectBackground,
  ClassModeMethods,
  DrillWebApi
) {
  // 小項目情報
  @Prop()
  unit!: Unit

  @Prop()
  publisherCode!: string

  @Prop()
  onClick!: Function

  // 一問一答: OO, 診断テスト: ST, 復習テスト: HS, 補助動画: HD
  private assistMaterialCode = this.$route.params.assistMaterialCode

  private classMode = 'HJ'

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  private classCategoryCode = { practice: 'HR', test: 'HT' }

  get styleSet(): any {
    const _: any = this
    return function (code: string) {
      return _.getSubjectStyle(code)
    }
  }

  protected get lessonId() {
    return this.$store.getters['lessonActionCable/lessonId']
  }

  // 補助教材ビデオ視聴時の処理
  private onClickMovie() {
    Vue.prototype.$loading.start()
    this.onClick()

    // 診断テスト、復習テスト、補助動画の開始
    this.$router.push({
      path: `/student/assist-material/video/${this.unit.curriculumSUnitId}`,
      query: {
        curriculumModeCode: this.assistMaterialCode,
        publisherCode: this.publisherCode,
      },
    })
    Vue.prototype.$loading.complete()
  }

  // 練習ボタン押下時の処理
  private async onClickPractice() {
    this.$store.commit('drillsV3/setTitleResult', '練習: ' + this.unit.name)
    localStorage.setItem('drillsV3/setTitleResult', '練習: ' + this.unit.name)
    Vue.prototype.$loading.start()
    this.onClick()

    // 一問一答の練習開始
    const startDrill = await this.startDrillApi({
      lessonId: this.lessonId,
      classModeCode: this.classMode,
      classCategoryCode: this.classCategoryCode['practice'],
      curriculumModeCode: this.assistMaterialCode,
      curriculumSUnitId: this.unit.curriculumSUnitId,
      publisherCode: this.publisherCode,
    })

    // ドリル開始APIの結果を受けて問題ページへ遷移する
    // 途中で抜けていた場合はそのpageへ遷移
    this.$router.push({
      path: `/student/assist-material/practice/${this.unit.curriculumSUnitId}`,
      query: {
        resultDrillId: String(startDrill.resultDrillId),
        pageType: startDrill.pageType,
        page: String(startDrill.page || ''),
        classCategoryCode: this.classCategoryCode['practice'], // 授業種別
        curriculumModeCode: this.assistMaterialCode,
        subjectCode: this.subjectCode,
      },
    })
    Vue.prototype.$loading.complete()
  }

  // テストボタン押下時の処理
  private async onClickTest(): Promise<any> {
    Vue.prototype.$loading.start()
    this.onClick()

    // 診断テスト、復習テスト、一問一答、補助動画のドリル開始
    const startDrill = await this.startDrillApi({
      lessonId: this.lessonId,
      classModeCode: this.classMode,
      classCategoryCode: this.classCategoryCode['test'],
      curriculumModeCode: this.assistMaterialCode,
      curriculumSUnitId: this.unit.curriculumSUnitId,
      publisherCode: this.publisherCode,
    })

    // ドリル開始APIの結果を受けて問題ページへ遷移する
    // 途中で抜けていた場合はそのpageへ遷移
    this.$router.push({
      name: 'drill',
      query: {
        resultDrillId: String(startDrill.resultDrillId),
        pageType: startDrill.pageType,
        page: String(startDrill.page || ''),
        // 以下は引き続き演習問題で利用するのに必要なため引き回す
        classCategoryCode: this.classCategoryCode['test'], // 授業種別
        curriculumModeCode: this.assistMaterialCode,
      },
    })
    Vue.prototype.$loading.complete()
  }

  // 動画視聴ボタン表示制御
  private get movieAble(): boolean {
    // 診断テスト/復習テスト/補助動画項目の内、動画が存在するもの
    const permitCodes = []
    permitCodes.push(this.ASSIST_MATERIAL_CODE.ST)
    permitCodes.push(this.ASSIST_MATERIAL_CODE.HS)
    permitCodes.push(this.ASSIST_MATERIAL_CODE.HD)

    return permitCodes.includes(this.assistMaterialCode) && this.unit.hasMovie
  }

  // 練習ボタン表示制御
  private get practiceAble(): boolean {
    // 一問一答のみ
    return this.assistMaterialCode == this.ASSIST_MATERIAL_CODE.OO
  }

  // テストボタン表示制御
  private get testAble(): boolean {
    // 現状存在するモード全てで表示
    const permitCodes = []
    permitCodes.push(this.ASSIST_MATERIAL_CODE.OO)
    permitCodes.push(this.ASSIST_MATERIAL_CODE.ST)
    permitCodes.push(this.ASSIST_MATERIAL_CODE.HS)
    permitCodes.push(this.ASSIST_MATERIAL_CODE.HD)
    permitCodes.push(this.ASSIST_MATERIAL_CODE.FC)
    permitCodes.push(this.ASSIST_MATERIAL_CODE.KT)

    return permitCodes.includes(this.assistMaterialCode)
  }
}

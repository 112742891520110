










































import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import FillRound from '@/components/atoms/FillRound.vue'
import InputWithSearch from '@/components/molecules/InputWithSearch.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import AssistMaterialUnitInformation, { Unit } from '@/components/organisms/AssistMaterialUnitInformation.vue'
import AssistMaterialUnitInformationRemoveBackground from '@/components/organisms/v3/AssistMaterialUnitInformationRemoveBackground.vue'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import VueRouter from 'vue-router'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

Component.registerHooks(['beforeRouteUpdate'])

type Grade = { id: number; name: string; code: string }
type Publisher = { id: number; name: string; code: string }

@Component({
  components: {
    SidebarSwitcher,
    LabelBase,
    ColoredBox,
    ButtonBase,
    FillRound,
    InputWithSearch,
    SelectBase,
    AssistMaterialUnitInformation,
    AssistMaterialUnitInformationRemoveBackground,
    StudentBottomPanel,
  },
})
export default class AssistMaterialSelect extends Mixins(LessonStudent, ClassModeMethods, ClassModeChangeAble) {
  private get unitComponent() {
    return Vue.prototype.$gdlsCookiesV3.isV3()
      ? 'AssistMaterialUnitInformationRemoveBackground'
      : 'AssistMaterialUnitInformation'
  }

  private get selectSubjectUrl() {
    return `/student/assist-material/subjects/${this.$route.params.assistMaterialCode}`
  }

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  private readonly subjectNames = { ko: '国語', su: '数学', ei: '英語', ri: '理科', sh: '社会' }

  private noticeCurrentPageText = '小項目選択中（補助）'

  private noticePageTitle = ''

  private colorType = 'gray'

  private get subjectName() {
    return this.subjectNames[this.subjectCode]
  }

  // ログイン生徒の学年コード
  private studentGradeCode = Vue.prototype.$cookies.get('dataGdls').grade?.code || ''

  // サービスコード 全教研: ZK
  private serviceCode = Vue.prototype.$cookies.get('dataGdls').serviceCode || ''

  // 補助教材コード 一問一答: OO, 診断テスト: ST, 復讐テスト: HS, 補助教材: HD
  private get assistMaterialCode() {
    return this.$route.params.assistMaterialCode || ''
  }

  private gradeOptionDatas: { text: string; value: string }[] = []
  private gradeSelectedData: string | null = null

  private publisherOptionDatas: { text: string; value: string }[] = []
  private publisherSelectedData: string | null = null

  private unitDatas: Unit[] = []

  private async mounted(): Promise<void> {
    Vue.prototype.$loading.start()
    await this.checkClassModeAndSideMenuMode(true)

    if (!this.subjectCode) this.redirectSelectSubject()

    await this.loadSelectDatas()
    await this.loadUnitDatas()

    this.noticeGroup()

    await this.getExistSupporterCallHistory()
    Vue.prototype.$loading.complete()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    console.info('-- AssistMaterialUnitInformation beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  /**
   * 教科選択画面へリダイレクト
   */
  private redirectSelectSubject() {
    this.$router.push(`/student/assist-material/subjects/${this.assistMaterialCode}`)
  }

  /**
   * セレクトボックスデータ取得
   */
  private async loadSelectDatas() {
    await this.loadGrades()
    await this.loadPublishers()
  }

  /**
   * 学年選択肢取得
   */
  private async loadGrades() {
    const { data }: { data: Grade[] } = await Vue.prototype.$http.httpWithToken.get(`/grades`)

    this.gradeOptionDatas = data.map((grade) => {
      return { text: grade.name, value: grade.code }
    })

    // cookieから前回学習時に選択していた学年コードを取得
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    const beforeSelectedGradeCode = cookie['selectedGradeCode']

    if (
      beforeSelectedGradeCode &&
      this.gradeOptionDatas.some((gradeData) => gradeData.value === beforeSelectedGradeCode)
    ) {
      // 前回学習時に選択していた学年コードがcookieに入っていれば同じ学年
      this.gradeSelectedData = beforeSelectedGradeCode

      delete cookie['selectedGradeCode']
      Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
    } else if (
      this.serviceCode === this.privateData?.serviceCode &&
      this.gradeOptionDatas.some((gradeData) => gradeData.value === this.privateData?.defaultGradeCode)
    ) {
      // 全教研の場合は「小6」がデフォルト
      this.gradeSelectedData = this.privateData?.defaultGradeCode
    } else if (this.studentGradeCode) {
      // 初期値はログイン生徒の学年
      this.gradeSelectedData = this.studentGradeCode
    } else {
      this.gradeSelectedData = this.gradeOptionDatas[0].value
    }
  }

  /**
   * 教科書選択肢取得
   */
  private async loadPublishers() {
    const params = { subjectCode: this.subjectCode, gradeCode: this.gradeSelectedData }
    const { data }: { data: Publisher[] } = await Vue.prototype.$http.httpWithToken.get(`/publishers`, {
      params: params,
    })

    this.publisherOptionDatas = data.map((publisher) => {
      return { text: publisher.name, value: publisher.code }
    })

    // cookieから前回学習時に選択していた教科書コードを取得
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    const beforeSelectedPublisherCode = cookie['selectedPublisherCode']

    if (
      beforeSelectedPublisherCode &&
      this.publisherOptionDatas.some((publisherData) => publisherData.value === beforeSelectedPublisherCode)
    ) {
      // 前回学習時に選択していた教科書コードがcookieに入っていれば同じ教科書
      this.publisherSelectedData = beforeSelectedPublisherCode

      delete cookie['selectedPublisherCode']
      Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
    } else if (
      this.serviceCode === this.privateData?.serviceCode &&
      this.publisherOptionDatas.some((publisherData) => publisherData.value === this.privateData?.defaultPublisherCode)
    ) {
      // 全教研の場合は「標準」がデフォルト
      this.publisherSelectedData = this.privateData?.defaultPublisherCode
    } else {
      this.publisherSelectedData = this.publisherOptionDatas[0].value
    }
  }

  /**
   * 小項目リスト取得
   */
  private async loadUnitDatas() {
    // 未選択のものがある場合はリクエスト無し
    if (!this.gradeSelectedData || !this.publisherSelectedData) return

    const params = {
      subjectCode: this.subjectCode,
      gradeCode: this.gradeSelectedData,
      publisherCode: this.publisherSelectedData,
      assistMaterialCode: this.assistMaterialCode,
      serviceCode: this.serviceCode,
    }
    const { data }: { data: Unit[] } = await Vue.prototype.$http.httpWithToken.get(
      `/publisher_curriculums_s_units/assist_material`,
      {
        params: params,
      }
    )

    this.unitDatas = data
  }

  private callSupporterClicked() {
    if (this.colorType === 'gray') {
      this.colorType = 'darkgray'
    }
    this.callSupporter(true)
  }

  private async getExistSupporterCallHistory() {
    if (this.lessonId) {
      try {
        const res = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/supporterCallHistory`)
        this.colorType = res.data.existSupporterCallHistory ? 'darkgray' : 'gray'
      } catch (e) {
        console.error(`サポーターを呼ぶボタンの履歴取得でエラーが発生しました。 ${e}`)
        throw e
      }
    }
  }

  /**
   * 学年コードと教科書コードをcookieにセットする
   */
  private setCookieGradeCodeAndPublisherCode() {
    const cookie = Vue.prototype.$cookies.get('dataGdls')
    cookie['selectedGradeCode'] = this.gradeSelectedData || ''
    cookie['selectedPublisherCode'] = this.publisherSelectedData || ''
    Vue.prototype.$cookies.set('dataGdls', cookie, Function(`return (${process.env.VUE_APP_COOKIE_EXPIRE})`)())
  }

  @Watch('gradeSelectedData')
  gradeSelectedDataChanged() {
    this.loadUnitDatas()
  }

  @Watch('publisherSelectedData')
  publisherSelectedDataChanged() {
    this.loadUnitDatas()
  }

  @Watch('lessonId')
  onLessonIdChanged() {
    this.getExistSupporterCallHistory()
  }
}
